import { CircularProgress } from "@mui/material";
// import Box from "@mui/material/Box";
// import Modal from "@mui/material/Modal";
import axios from "axios";
import React, { useState } from "react";
import { toast } from "react-toastify";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import { useAuth } from "../../Utils/Auth";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
// const style = {
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   width: 400,
//   bgcolor: "background.paper",
//   border: "2px solid #000",
//   boxShadow: 24,
//   p: 4,
// };
const Translate = () => {
  const { isAdmin } = useAuth();
  const navigate = useNavigate()
  useEffect(() => {
    if (!isAdmin) {
      navigate(`/admin/login`);
    }
  }, [isAdmin]);
  const [inputBody, setinputBody] = useState("");
  const [translatedBody, settranslatedBody] = useState("");
  const [istranslating, setistranslating] = useState(false);
  // const [doctitle, setdoctitle] = useState("");
  // const [receipientMail, setreceipientMail] = useState("");
  const [targetLanguage, settargetLanguage] = useState("de");

  const handleTranslate = async () => {
    try {
      if (inputBody.trim() === "") return toast.error("Empty input");
      setistranslating(true);
      const response = await axios.post("/api/v1/translate", {
        message: inputBody,
        targetLanguage,
      });
      if (response.data.success) {
        settranslatedBody(response.data.data);
        toast.success("Translated successfully");
        setistranslating(false);
      }
    } catch (error) {
      setistranslating(false);
      if (error.response && error.response.data) {
        return toast.error(error.response.data.error);
      }
    }
  };
  // const handleShareDocument = async () => {
  //   if (receipientMail.trim() === "" || doctitle.trim() === "")
  //     return toast.error("Empty fields");
  //   try {
  //     setistranslating(true);
  //     const response = await axios.post("/api/v1/gdoc/share", {
  //       email: receipientMail,
  //       title: doctitle,
  //       body: translatedBody,
  //     });
  //     if (response.data.success) {
  //       handleClose();
  //       toast.success("File shared successfully");
  //       setistranslating(false);
  //     }
  //   } catch (error) {
  //     setistranslating(false);
  //     if (error.response && error.response.data) {
  //       return toast.error(error.response.data.error);
  //     }
  //   }
  // };

  // const [open, setOpen] = React.useState(false);
  // const handleOpen = () => {
  //   setOpen(true);
  // };
  // const handleClose = () => {
  //   setdoctitle("");
  //   setreceipientMail("");
  //   setOpen(false);
  // };
  if (isAdmin)
    return (
      <div className="trendist_translate">
        <div className="row mb-2 mt-2">
          <div className="col-6"></div>
          <div className="col-6">
            <button
              className="p-1 me-4"
              style={{
                backgroundColor: "black",
                color: "white",
                width: "40px",
              }}
              onClick={() => {
                if (targetLanguage === "de") {
                  settargetLanguage("en-GB");
                } else {
                  settargetLanguage("de");
                }
              }}
              type="button"
            >
              <SwapHorizIcon style={{ fontSize: "30px" }} />
            </button>
            <button
              className="p-1 me-4"
              style={{ backgroundColor: "red", color: "white" }}
              onClick={() => {
                setinputBody("");
                settranslatedBody("");
              }}
              type="button"
            >
              Clear all
            </button>
            <button
              className="p-1 me-4"
              style={{ backgroundColor: "skyblue" }}
              onClick={() => handleTranslate()}
              type="button"
            >
              Translate
            </button>
            {/* <button
            className="p-1 me-4"
            style={{ backgroundColor: "lightgreen" }}
            type="button"
            onClick={() => handleOpen()}
          >
            Share
          </button>
          <button
            className="p-1 me-4"
            style={{ backgroundColor: "green", color: "white" }}
            type="button"
            onClick={() => handleDownload()}
          >
            Download
          </button> */}
            {istranslating ? <CircularProgress /> : null}
          </div>
        </div>
        <div className="row">
          <div className="col border border-dark">
            <div class="form-group">
              {targetLanguage === "de" ? (
                <>
                  <label for="exampleFormControlTextarea1">
                    Enter Text to be translated...(in English)
                  </label>
                </>
              ) : (
                <>
                  <label for="exampleFormControlTextarea1">
                    Enter Text to be translated...(in German)
                  </label>
                </>
              )}

              <textarea
                onChange={(e) => setinputBody(e.target.value)}
                value={inputBody}
                class="h-auto trendist_translate form-control rounded-0"
                rows="50"
              ></textarea>
            </div>
          </div>
          <div className="col border border-dark">
            <div class="form-group">
              {targetLanguage === "de" ? (
                <>
                  <label for="exampleFormControlTextarea1">
                    Translated Text (in German)
                  </label>
                </>
              ) : (
                <>
                  <label for="exampleFormControlTextarea1">
                    Translated Text (in English)
                  </label>
                </>
              )}

              <textarea
                onChange={(e) => settranslatedBody(e.target.value)}
                value={translatedBody}
                class="h-auto trendist_translate form-control rounded-0"
                rows="50"
              ></textarea>
            </div>
          </div>
        </div>
        {/* <Modal
        open={open}
        onClose={handleClose}
        className="trendistmailform"
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div class="form-group">
            <label for="exampleFormControlTextarea1">Document Title</label>
            <input
              onChange={(e) => setdoctitle(e.target.value)}
              value={doctitle}
              class="h-auto trendist_translate form-control rounded-0"
            ></input>
          </div>
          <div class="form-group">
            <label for="exampleFormControlTextarea1">Enter Email</label>
            <input
              onChange={(e) => setreceipientMail(e.target.value)}
              value={receipientMail}
              class="h-auto trendist_translate form-control rounded-0"
            ></input>
          </div>
          <button
            className="m-2 p-2"
            style={{ backgroundColor: "blue", color: "white" }}
            type="button"
            onClick={() => handleShareDocument()}
          >
            Share
          </button>
        </Box>
      </Modal> */}
      </div>
    );
};

export default Translate;
