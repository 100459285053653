import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

const Logs = () => {
  const [logs, setlogs] = useState([]);
  const getlogs = async () => {
    try {
      const response = await axios.get("/api/v1/mail/trendistlogs");
      if (response.data.success) {
        setlogs(response.data.data);
      }
    } catch (error) {
      console.log(error);
      if (error.response && error.response.data) {
        toast.error(error.response.data.error);
      }
    }
  };
  useEffect(() => {
    getlogs();
  }, []);
  return (
    <table class="table trendistmailform">
      <thead>
        <tr>
          <th scope="col">Trendist</th>
          <th scope="col">Timeline</th>
          <th scope="col">Email</th>
          <th scope="col">Type</th>
          <th scope="col">Subject</th>
          <th scope="col">Topic</th>
          <th scope="col">Week</th>
          <th scope="col">Template</th>
        </tr>
      </thead>
      <tbody>
        {logs.map((v, i) => {
          return (
            <tr>
              <th scope="row">{i + 1}</th>
              <td>{new Date(v.createdAt).toString()} </td>
              <td style={{ fontWeight: "bold" }}>{v.email} </td>
              {v.live ? (
                <td style={{ color: "green", fontSize: "20px" }}>Live</td>
              ) : (
                <td style={{ color: "red", fontSize: "20px" }}>Testing</td>
              )}

              <td>{v.subject} </td>
              <td>{v.topic} </td>
              <td>Week:{v.week} </td>
              <td
                style={{ color: "blue" }}
                className="pointer"
                onClick={() => window.open(v.template)}
              >
                View
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default Logs;
