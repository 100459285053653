import React, { useState } from 'react'
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
// import { useAuth } from "../../Utils/Auth";
import Banner from "../../Assets/Images/HomeBanner2.jpg";
import "../Home/index.css";
import LogoNav from "../../Components/Navbar/LogoNav";
import { Checkbox } from "@mui/material";
import Axios from "axios"
import { useAuth } from '../../Utils/Auth';

const UserAdminLogin = () => {
    const [password, setPassword] = useState("");
    const [isHiddenPass, setisHiddenPass] = useState(true);
    const navigate = useNavigate()
    const { isUserAdmin, setIsUserAdmin, setIsUserAdminLoading } = useAuth();

    const Login = async () => {
        await Axios({
            url: "/api/v1/auth/login",
            method: "post",
            data: {
                password
            },
        })
            .then((res) => {
                if (res.data.success) {
                    // setTopicGlobal(res.data.topic)
                    setIsUserAdminLoading(true);
                    setIsUserAdmin(true);
                    navigate(`/user-admin-check`);
                    toast.success("Login Success");
                }
            })
            .catch((err) => {
                toast.error(err.response && err.response.data.error)
            });
    };
    const handleChangePassHide = () => {
        setisHiddenPass(!isHiddenPass);
    };

    return <div className="home_root">
        <LogoNav topic={"admin/login"} />
        <div className="home_main">
            <div className="container h-100 d-flex align-items-center justify-content-between">
                <div className="home_right">
                    <div className="homeBanner">
                        <img className="img-fluid" src={Banner} alt="banner" />
                    </div>
                </div>
                <div className="home_left">
                    <div className="home_text">
                        <img
                            src="https://zefyron-assets.fra1.digitaloceanspaces.com/Partners/Logo_on_screen_1.png"
                            alt="wvib"
                            lazy
                        />
                        <p className="home-title">Stay ahead of the trend curve</p>
                        <p className="home-desc mt-24">
                            Trendist is a bi-weekly curated newsletter which combines
                            cutting-edge AI with the deep expertise of our analysts to
                            extract the most relevant content from across the web.
                        </p>
                        {isUserAdmin ? <button
                            className="home_redirect_btn"
                            onClick={() => navigate(`/wvib/admin/views`)}
                        >
                            Article Views{" "}
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="14"
                                height="14"
                                fill="none"
                                viewBox="0 0 14 14"
                                className="ms-3 home_redirect_btn_svg"
                            >
                                <path
                                    fill="#000"
                                    d="M7 .333L5.825 1.508l4.65 4.659H.333v1.666h10.142l-4.65 4.659L7 13.667 13.667 7 7 .333z"
                                ></path>
                            </svg>
                        </button> : <>
                            <div className="d-flex justify-content-lg-start align-items-center">
                                <div className="home_login-main">
                                    <input
                                        type={isHiddenPass ? "password" : "text"}
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        placeholder="Enter your passcode"
                                        className="body1"
                                    />
                                    <button onClick={Login}>Login</button>
                                </div>
                            </div>
                            <div className="w_100 mt-8 ml-16 input_checkbox">
                                <Checkbox
                                    checked={!isHiddenPass}
                                    onChange={handleChangePassHide}
                                    label="Zefyron User"
                                    inputProps={{ "aria-label": "controlled" }}
                                />
                                <lable className="Nunito body2">Show passcode</lable>
                            </div>
                        </>
                        }
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default UserAdminLogin