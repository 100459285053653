import axios from "axios";
import React, { useState, useEffect } from "react";
import DocCard from "./DocCard";
import { useAuth } from "../../Utils/Auth";
import { useNavigate } from "react-router-dom";

const AllDocs = () => {
  const [docs, setDocs] = useState([]);
  const { isAdmin } = useAuth();
  const navigate = useNavigate()

  const getAllDocs = async () => {
    await axios({
      url: "/api/v1/trendist/doc",
      method: "get",
    }).then((res) => {
      setDocs(res.data.data);
    });
  };
  useEffect(() => {
    if (!isAdmin) {
      navigate(`/admin/login`);
    }
  }, [isAdmin]);

  useEffect(() => {
    getAllDocs();
  }, []);

  return (
    <div className="row">
      {docs &&
        docs.length > 0 &&
        docs.map((doc) => (
          <DocCard key={doc.id} doc={doc} getAllDocs={getAllDocs} />
        ))}
    </div>
  );
};

export default AllDocs;
