import React from "react";

const IndexTopic = ({ json }) => {
  return (
    <div className="d-flex align-items-center indextopicRoot mb-24 basicMargin">
      <img alt="test" src={json.image} />
      <div className="ml-24">
        <a href={`#${json.bookmarkId}`}>
          <p className="editorCaption grey_ca">{json.topic}</p>
          <p className="editorTitle2">
            {json.content}{" "}
            <span className="ml-12" style={{ color: "#4AD7D1" }}>
              ➤
            </span>{" "}
          </p>
        </a>
      </div>
    </div>
  );
};

export default IndexTopic;
