import React from "react";
import Axios from "axios";
import { useState } from "react";
import { toast } from "react-toastify";

const ArticleForm = ({
  setStep,
  coverImage,
  setCoverImage,
  heading,
  setHeading,
  url,
  setUrl,
  description,
  setDescription,
  createArticle,
  isUpdate,updateArticle,
  source,
  setSource
}) => {
  const [isUploading, setIsUploading] = useState(false);
  const handlePicUpload = (e) => {
    const image = e.target.files[0];
    if (image.size > 2048 * 1024) {
      toast.error("File size exceeded 2MB!", { position: "bottom-right" });
      return;
    }
    const formdata = new FormData();
    formdata.append("cover", image);
    setIsUploading(true);
    Axios({
      method: "post",
      url: "/api/v1/article/upload",
      data: formdata,
    })
      .then((res) => {
        const { data } = res.data;
        setCoverImage(data);
        setIsUploading(false);
        toast.success("Profile Image upload", { position: "bottom-right" });
      })
      .catch(() => {
        setIsUploading(false);
        toast.error("Error in upload", { position: "bottom-right" });
      });
  };
  return (
    <div className="admin-dashboard-form-root">
      <div className="admin-dashboard-form">
        <div className="admin-dashboard-form-main position-relative">
          <button className="trans_cta" onClick={() => setStep(1)}>
            Back
          </button>
          <div className="row">
            <div className="col-12">
              <div className="admin-dashboard-input">
                <p className="title2">Cover Image</p>
                <div className="admin-coverImage">
                  {isUploading ? (
                    "uploading..."
                  ) : coverImage ? (
                    <>
                      <img src={coverImage} alt="cover image" />
                      <br />
                      <p
                        style={{ cursor: "pointer" }}
                        onClick={() => setCoverImage("")}
                      >
                        Remove
                      </p>
                    </>
                  ) : (
                    <>
                      <label htmlFor="coverImage">Upload</label>
                      <input
                        type="file"
                        id="coverImage"
                        onChange={handlePicUpload}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="col-12 mt-24">
              <div className="admin-dashboard-input">
                <label className="title2" htmlFor="topic">
                  Heading
                </label>
                <input
                  type="text"
                  value={heading}
                  onChange={(e) => setHeading(e.target.value)}
                />
              </div>
            </div>
            <div className="col-12 mt-24">
              <div className="admin-dashboard-input">
                <label className="title2" htmlFor="topic">
                  Url
                </label>
                <input
                  type="text"
                  value={url}
                  onChange={(e) => setUrl(e.target.value)}
                />
              </div>
            </div>
            <div className="col-12 mt-24">
              <div className="admin-dashboard-input">
                <label className="title2" htmlFor="topic">
                  Source
                </label>
                <input type="text" value={source} onChange={(e) => setSource(e.target.value)} />
              </div>
            </div>
            <div className="col-12 mt-24">
              <div className="admin-dashboard-input">
                <label className="title2" htmlFor="topic">
                  Description
                </label>
                <textarea
                  rows={6}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                ></textarea>
              </div>
            </div>
          </div>
          {
            isUpdate ? <button
            className="zefy_CTA_01_small_bg_black mt-48"
            onClick={updateArticle}
          >
            Update
          </button> : <button
            className="zefy_CTA_01_small_bg_black mt-48"
            onClick={createArticle}
          >
            Create
          </button>
          }
          
          
        </div>
      </div>
    </div>
  );
};

export default ArticleForm;
