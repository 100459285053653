const getofficialmails = (type) => {
  switch (type) {
    case "Blockchain":
      return [
        "mathias.mostertz@linde.com",
        "philipp.von.breitenbuch@linde.com",
        "renand.grando@linde.com",
        "Susanne.hahn@skvinvest.de",
        "aparna@zefyron.com",

        "rahul@vcbay.co",
        "chirag@zefyron.com",
        "nayana@vcbay.co",
        "ashutosh@vcbay.co",
        "akshada@vcbay.co",
      ];

    case "Electricity Procurement":
      return [
        "mathias.mostertz@linde.com",
        "philipp.von.breitenbuch@linde.com",
        "Susanne.hahn@skvinvest.de",
        "aparna@zefyron.com",

        "rahul@vcbay.co",
        "chirag@zefyron.com",
        "nayana@vcbay.co",
        "ashutosh@vcbay.co",
        "akshada@vcbay.co",
      ];

    case "Geoengineering":
      return [
        "philipp.von.breitenbuch@linde.com",
        "mathias.mostertz@linde.com",
        "Susanne.hahn@skvinvest.de",
        "aparna@zefyron.com",

        "rahul@vcbay.co",
        "chirag@zefyron.com",
        "nayana@vcbay.co",
        "enthuashu@gmail.com",
        "ashutosh@vcbay.co",
        "akshada@vcbay.co",
      ];

    case "Digital Healthcare & AI":
      return [
        "mathias.mostertz@linde.com",
        "philipp.von.breitenbuch@linde.com",
        "nchitwoo@lincare.com",
        "tiago.esteves@linde.com",
        "Susanne.hahn@skvinvest.de",
        "aparna@zefyron.com",

        "rahul@vcbay.co",
        "chirag@zefyron.com",
        "nayana@vcbay.co",
        "enthuashu@gmail.com",
        "ashutosh@vcbay.co",
        "akshada@vcbay.co",
      ];
    case "Automation in Metals Manufacturing":
      return [
        "Marcel.Christians@wieland.com",
        "Lennard.Hermann@wieland.com",
        "Susanne.hahn@skvinvest.de",
        "aparna@zefyron.com",

        "rahul@vcbay.co",
        "chirag@zefyron.com",
        "nayana@vcbay.co",
        "ashutosh@vcbay.co",
        "akshada@vcbay.co",
      ];
    case "HR News":
      return [
        "m.pross@wackenhut.de",
        "j.wick@wackenhut.de",
        "j.kraft@wackenhut.de",
        "e.wehle@wackenhut.de",
        "e.wennagel@wackenhut.de",
        "j.spannagel@wackenhut.de",
        "f.kuebler@wackenhut.de",
        "k.weihing@wackenhut.de",
        "Susanne.hahn@skvinvest.de",
        "aparna@zefyron.com",

        "rahul@vcbay.co",
        "chirag@zefyron.com",
        "nayana@vcbay.co",
        "ashutosh@vcbay.co",
        "akshada@vcbay.co",
      ];
    case "Innovation im Autohandel":
      return [
        "m.pross@wackenhut.de",
        "d.tobolla@wackenhut.de",
        "k.sautter@wackenhut.de",
        "j.wick@wackenhut.de",
        "Susanne.hahn@skvinvest.de",
        "aparna@zefyron.com",

        "rahul@vcbay.co",
        "chirag@zefyron.com",
        "nayana@vcbay.co",
        "ashutosh@vcbay.co",
        "akshada@vcbay.co",
      ];
    case "Cybersecurity":
      return [
        "wd@adwm-gmbh.de",
        "a.winkler@senate-international.org",
        "Susanne.hahn@skvinvest.de",
        "aparna@zefyron.com",

        "rahul@vcbay.co",
        "chirag@zefyron.com",
        "nayana@vcbay.co",
        "ashutosh@vcbay.co",
        "akshada@vcbay.co",
      ];
    case "Geopolitical situation (in the Energy Sector)":
      return [
        "wd@adwm-gmbh.de",
        "a.winkler@senate-international.org",
        "Susanne.hahn@skvinvest.de",
        "aparna@zefyron.com",

        "rahul@vcbay.co",
        "chirag@zefyron.com",
        "nayana@vcbay.co",
        "ashutosh@vcbay.co",
        "akshada@vcbay.co",
      ];
    case "INNO Tech Monitoring":
      return [
        "linus.boehnlein@mercedes-benz.com",
        "yannick.dietrich@mercedes-benz.com",
        "volker.Gesmann@skvinvest.de",
        "Susanne.hahn@skvinvest.de",
        "aparna@zefyron.com",

        "rahul@vcbay.co",
        "chirag@zefyron.com",
        "nayana@vcbay.co",
        "ashutosh@vcbay.co",
        "akshada@vcbay.co",
      ];
    case "Wire Arc Additive Manufacturing":
      return [
        "Martin.Elser@liebherr.com",
        "Moritz.Merkle@liebherr.com",
        "volker.Gesmann@skvinvest.de",
        "Susanne.hahn@skvinvest.de",
        "aparna@zefyron.com",

        "rahul@vcbay.co",
        "chirag@zefyron.com",
        "nayana@vcbay.co",
        "ashutosh@vcbay.co",
        "akshada@vcbay.co",
      ];
    case "Project Bio":
      return [
        // "aparna@zefyron.com",
        "rahul@vcbay.co",
        "ashutosh@vcbay.co",
        // "susanne.hahn@skvinvest.de",
        // "volker.gesmann@skvinvest.de",
        // "kai.siebert@skvinvest.de",
        // "chirag@zefyron.com",
        // "nayana@vcbay.co",

        // "jl@angelinvest.ventures",
        // "christian@nebenan.de",
        // "christian@pt1.vc",
        // "carsten.maschmeyer@maschmeyer-group.de",
        // "christian@pitch.io",
        // "christian@superlist.com",
        // "jonathan@tio.holdings",
        // "jonathan@teklu.net",
        // "matthias.hilpert@mh2capital.com ",

        "marie@foodlabs.com",
        "f.cordesmeyer@goodseedventures.com",
        "frank@goodseedventures.com",
        "neeraj@tet.vc",
        "eric@happinesscapital.com",
        "bjorn.witte@bluehorizon.com",
        "sylvia@techfounders.com",
        "albrecht@provegincubator.com",
        "aleksandra@pointninecap.com",
        "aleksandra@pointnine.com",
        "gary@purpleorange.com",
        "george.howard@cptcap.com",
        "alex@shiocap.com",
        "anthony@agronomics.im",
        "ivan@fiveseasons.vc",
        "eric@astanor.com",
        "jan@simoncapital.com",
        "kristofer.fichtner@gmail.com",
        "bjoern.witte@bluehorizon.com",
        "mail@kristofer.de",
      ];

    default:
      break;
  }
};

export default getofficialmails;
