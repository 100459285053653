import React, { useState, useEffect } from "react";
import WeekNavbar from "../../Components/Navbar/WeekNavar";
import "./index.css";
import { useAuth } from "../../Utils/Auth";
import Pagination from "@mui/material/Pagination";
import Axios from "axios";
import Week from "./Week";
import Footer from "../../Components/Footer/Footer";
import { useParams } from "react-router-dom";

const Dashboard = () => {
  const { user, topicGlobal } = useAuth();
  const [articleCount, setArticleCount] = useState(0);
  const [articlePage, setArticlePage] = useState(1);
  const [articles, setArticles] = useState([]);
  const {topic} = useParams()
  function topFunction() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }
  useEffect(() => {
    topFunction();
  }, []);

  const getArticles = async () => {
    await Axios({
      url: `/api/v1/article/all/${topic}?page=${articlePage}&size=4`,
      method: "get",
    })
      .then((res) => {
        topFunction();
        const newArr = [...res.data.data].sort((a, b) => b.date - a.date);
        setArticles(newArr);
        setArticleCount(parseInt(res.data.total_weeks));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (user && topic) {
      getArticles();
    }
  }, [user, articlePage]);

  return (
    <div className="dashboard-root">
      <WeekNavbar />
      <div className="dashboard-head">
        <img src={user && user.logo} alt={user?.companyName} />
        <h5 className="mt-24 fw-4">{topicGlobal?.name}</h5>
      </div>
      {/* { */}
      {/* user ? <> */}
      <div className="dashboard-main">
        {articles.length > 0 &&
          articles.map((article, i) => (
            <Week key={i} article={article} i={i} articlePage={articlePage} />
          ))}
        <div className="dashboard-pagination mt-64 mb-64">
          <Pagination
            className="dashboard-page"
            count={articleCount ? Math.ceil(articleCount / 4) : 0}
            page={articlePage}
            onChange={(event, value) => setArticlePage(value)}
          />
        </div>
        <Footer />
      </div>
      {/* </> : null */}
      {/* } */}
    </div>
  );
};

export default Dashboard;
