import React from "react";
import { useNavigate } from "react-router-dom";
// import LogoNav from "../../Components/Navbar/LogoNav";
import './404page.css'

const ErrorPage = () => {
  const history = useNavigate();
  return (
    <div className="home_root">
      {/* <LogoNav/> */}
      <div className="options_root">
        <div className="row w-100">
          <div className="col-lg-5 col-md-12 col-sm-12 col-12 mt-lg-0 mt-md-40 mt-sm-40 mt-40">
            <div className="options_left_box_root">
              <div className="options_left_box_main">
                <div className="options_left_box_back d-flex align-items-center text-start">
                  {/* <span></span> */}
                  <p className="options_left_box_backp">Oops! Page not found.</p>
                </div>
                <div className="options_left_box_head mt-40 text-start">
                  <h3 className="mb-0">404 Error</h3>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-7 col-md-12 col-sm-12 col-12 mt-lg-0 mt-md-72 mt-sm-72 mt-72">
            <div className="options_right_box_root">
              <div className="options_right_form_root">
                <div className="w_70">
                  <p className="title1 mb-0">
                    Sorry but the page you are looking for does not exist. It
                    might have been removed, name changed or is temporarily
                    unavailble.
                  </p>

                  <button
                    className="zefy_CTA_04_md_fill_width mt-72"
                    onClick={() => history(-1)}
                  >
                    Take me back
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
