import React from "react";
import { useNavigate } from "react-router-dom";

const Week = ({ article, i, articlePage }) => {
  const navigate = useNavigate();

  return (
    <div className="dashboard-article-root">
      <div
        className={`dashboard-article-main ${i === 0 ? "dashboard-topborder" : ""
          }`}
      >
        <div className="row justify-content-center">
          <div className="col-lg-8 col-md-9 col-sm-12 col-12">
            <div className="row">
              <div className="col-lg-5 col-md-5 col-sm-4 col-12 d-flex justify-content-lg-start justify-content-md-start justify-content-center justify-content-sm-start">
                <div className="dashboard-article_week d-flex flex-lg-column flex-md-column flex-sm-column flex-row align-items-lg-start align-items-md-start align-items-sm-start align-items-center ">
                  <div>
                    <p className="head4">Week {article?.articles[0]?.week}</p>
                    <p className="title1">{new Date(article?.date).toLocaleDateString('en-GB')}</p>
                  </div>
                  {i === 0 && articlePage === 1 ? (
                    <div className="dashboard-article_new mt-1 ml-lg-0 ml-md-0 ml-sm-0 ml-24">
                      <p className="body1_pop">NEW</p>
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="col-lg-7 col-md-7 col-sm-8 col-12 mt-lg-0 mt-md-0 mt-sm-0 mt-24" onClick={() =>
                navigate(`/article/${article?.articles[0]?.week}/${article?.articles[0]?.topic_id?._id}`)
              }>

                <div className="dashboard-article-img">
                  <img src={article?.articles[0].cover} alt="Article1" />
                  <div className="article_topic-bottom">
                    {" "}
                    <p className="article_topic-p">{article?.articles[0].topic_id?.name} </p>
                  </div>
                </div>
                {/* {article?.articles?.map((data, index) => (
                    <div
                      key={data.topic_id?._id}
                      className={`col-lg-${article?.articles.length === 3 ? 4 : 6} col-md-6 col-12 ${index !== 0 ? "mt-lg-0 mt-md-0 mt-sm-24 mt-24" : ""
                        }`}
                      onClick={() =>
                        navigate(`/article/${article?.week}/${data.topic_id?._id}`)
                      }
                    >
                      <div className="dashboard-article-img">
                        <img src={data.cover} alt="Article1" style={{ maxHeight: article?.articles.length === 3 ? 164 : 'auto', objectFit: article?.articles.length === 3 ? 'cover' : null }} />
                        <div className="article_topic-bottom">
                          {" "}
                          <p className="article_topic-p">{data.topic_id?.name} </p>
                        </div>
                      </div>
                    </div>
                  ))} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Week;
