import React, { useEffect, useState } from 'react'
import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ArrowDown from '../../Assets/Icons/ArrowDown';
import ViewBox from './ViewBox';

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({    
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowDown />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor: "#ffffff",
    flexDirection: 'row',
    padding:"20px 0",
    '&:not(:last-child)': {
        borderBottom: "1px solid rgba(202, 202, 202, 0.3)",
    },
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(180deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(2),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const ViewWeekComp = ({ doc }) => {
    const [Topics, setTopics] = useState(null)
    const [expanded, setExpanded] = React.useState('panel');

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    useEffect(() => {
        if (doc) {
            const groupByTopic = doc.document.reduce((group, product) => {
                const { topic_id } = product;
                group[topic_id] = group[topic_id] ? group[topic_id] : [];
                group[topic_id].push(product);
                return group;
            }, {});
            setTopics(groupByTopic)
        }

    }, [doc])
    return <div className="dash-total_views_weak_root">
        <div className="dash-total_views_weak_head d-flex align-items-center justify-content-between">
            <p className="dash-total_views_p1">Week {doc.document[0].week}</p>
            <p className="body1_pop">{new Date(doc._id).toLocaleDateString('en-GB')}</p>
        </div>
        <div className="dash-total_views_weak_main">
            {
                Topics && Object.keys(Topics).map((topic, i) => <Accordion key={i} expanded={expanded === `panel${i + 1}`} onChange={handleChange(`panel${i + 1}`)}>
                    <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                        <div className='d-flex align-items-center'>
                            <div className='dash-total-viewBox'>
                                <ViewBox
                                    data={Topics[topic]}
                                />
                                <p className="body2_inter">Views</p>
                            </div>
                            <p className='dash-total_views_p1 ml-32'>{Topics[topic][0]?.topic[0]?.name}</p>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails>
                        {Topics[topic] && Topics[topic].length > 0 && Topics[topic].map((docdata, i) => <div key={i} className="dash-total_views_weak_">
                            <div className='d-flex align-items-center'>
                                <img src={docdata.cover} alt="topic" className='dash-total_views_weak_img' />
                                <div className='ml-24'>
                                    <p className='caption color_grey_46 fw-9'>{docdata.source}</p>
                                    <p className='dash-total_views_p2 mt-10 dash-total_views_p_maxW'><a href={docdata.link} target="_blank" rel="noopener noreferrer">{docdata.title}</a> <span className='aqua'>➤</span></p>
                                </div>
                            </div>
                            <div className='text-center'>
                                <p className="dash-total_views_p">{docdata.views}</p>
                                <p className="body2_inter">Views</p>
                            </div>
                        </div>)}
                    </AccordionDetails>
                </Accordion>)
            }
        </div>
    </div>
}

export default ViewWeekComp