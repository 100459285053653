import React from "react";
import Paragraph from "./Paragraph";
import BoldContent from "./BoldContent";
import Image from "./Image";
import MainHeading from "./MainHeading";
import IndexTopic from "./IndexTopic";
import TitleCover from "./TitleCover";
import Hyperlink from "./Hyperlink";
import BulletList from "./BulletList";
import PageBreak from "./PageBreak";

const GetElementType = ({ json }) => {
  switch (json.elementType) {
    case "paragraph":
      return <Paragraph json={json} />;

    case "boldcontent":
      return <BoldContent json={json} />;

    case "image":
      return <Image json={json} />;
    case "mainheading":
      return <MainHeading json={json} />;
    case "indextopic":
      return <IndexTopic json={json} />;
    case "titlecover":
      return <TitleCover json={json} />;
    case "hyperlink":
      return <Hyperlink json={json} />;
    case "bulletlist":
      return <BulletList json={json} />;
    case "page-break":
      return <PageBreak json={json} />;

    default:
      break;
  }
};

export default GetElementType;
