import React, { useState } from "react";
import "./index.css";
import Axios from "axios";
import { useEffect } from "react";

const AskInfo = ({
  setStep,
  companyName,
  setCompanyName,
  topic,
  setTopic,
  week,
  setWeek,
  date,
  setDate,
  isUpdate,
  user_id,
  topic_id,
}) => {
  const [users, setUsers] = useState([]);
  const [userTopics, setTopics] = useState([]);
  const getCompanis = async () => {
    await Axios({
      url: "/api/v1/user/getUsers",
      method: "get",
    })
      .then((res) => {
        setUsers(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getTopics = async (id) => {
    await Axios({
      url: `/api/v1/article/user/${id}`,
      method: "get",
    })
      .then((res) => {
        setTopics(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleNext = () => {
    if (companyName && topic && week) {
      setStep(2);
    }
  };
  useEffect(() => {
    getCompanis();
  }, []);
  useEffect(() => {
    if (companyName) {
      getTopics(companyName);
    }
  }, [companyName]);
  return (
    <div className="admin-dashboard-form-root">
      <div className="admin-dashboard-form">
        <div className="admin-dashboard-form-main">
          <div className="row">
            <div className="col-12">
              <div className="admin-dashboard-input">
                {
                  isUpdate ? <>
                    <p className="title2">Company</p>
                    <div className="mt-16">
                      <img src={user_id.logo} alt="google" style={{ height: 50 }} />
                    </div>
                  </> : <>
                    <p className="title2">Choose Company</p>
                    <div className="d-flex align-items-center mt-8">
                      {users.length > 0
                        ? users.map((user) => (
                          <div key={user._id} className="admin-form-label-main">
                            <input
                              type="radio"
                              name={"companyName"}
                              id={user._id}
                              value={user._id}
                              // checked={companyName === "google"}
                              onChange={() => setCompanyName(user._id)}
                            />
                            <label htmlFor={user._id} className="radio-images">
                              <img src={user.logo} alt="google" />
                            </label>
                          </div>
                        ))
                        : null}
                    </div>
                  </>
                }


              </div>
            </div>

            <div className="col-12 mt-24">
              <div className="admin-dashboard-input">
                <label className="title2" htmlFor="topic">
                  Select week
                </label>
                <select
                  value={week}
                  onChange={(e) => setWeek(e.target.value)}
                  className="body1"
                  name=""
                  id=""
                >
                  <option value="">Select week</option>
                  <option value="1">week 1</option>
                  <option value="2">week 2</option>
                  <option value="3">week 3</option>
                  <option value="4">week 4</option>
                  <option value="5">week 5</option>
                  <option value="6">week 6</option>
                  <option value="7">week 7</option>
                  <option value="8">week 8</option>
                  <option value="9">week 9</option>
                  <option value="10">week 10</option>
                  <option value="11">week 11</option>
                  <option value="12">week 12</option>
                  <option value="13">week 13</option>
                  <option value="14">week 14</option>
                  <option value="15">week 15</option>
                  <option value="16">week 16</option>
                  <option value="17">week 17</option>
                  <option value="18">week 18</option>
                  <option value="19">week 19</option>
                  <option value="20">week 20</option>
                  <option value="21">week 21</option>
                  <option value="22">week 22</option>
                  <option value="23">week 23</option>
                  <option value="24">week 24</option>
                  <option value="25">week 25</option>
                  <option value="26">week 26</option>
                  <option value="27">week 27</option>
                  <option value="28">week 28</option>
                  <option value="29">week 29</option>
                  <option value="30">week 30</option>
                  <option value="31">week 31</option>
                  <option value="32">week 32</option>
                  <option value="33">week 33</option>
                  <option value="34">week 34</option>
                  <option value="35">week 35</option>
                  <option value="36">week 36</option>
                  <option value="37">week 37</option>
                  <option value="38">week 38</option>
                  <option value="39">week 39</option>
                  <option value="40">week 40</option>
                  <option value="41">week 41</option>
                  <option value="42">week 42</option>
                  <option value="43">week 43</option>
                  <option value="44">week 44</option>
                  <option value="45">week 45</option>
                  <option value="46">week 46</option>
                  <option value="47">week 47</option>
                  <option value="48">week 48</option>
                  <option value="49">week 49</option>
                  <option value="50">week 50</option>
                  <option value="51">week 51</option>
                  <option value="52">week 52</option>
                </select>
              </div>
            </div>
            <div className="col-12 mt-24">
              <div className="admin-dashboard-input">
                <label className="title2" htmlFor="topic">
                  Select topic
                </label>
                {
                  isUpdate ? <select
                    className="body1"
                    disabled
                  >

                    <option value="">{topic_id.name}</option>
                    
                  </select> : <select
                    value={topic}
                    onChange={(e) => setTopic(e.target.value)}
                    className="body1"
                    name=""
                    id=""
                  >

                    <option value="">Select Topic</option>
                    {userTopics.length > 0
                      ? userTopics.map((res) => (
                        <option key={res._id} value={res._id}>
                          {res.name}
                        </option>
                      ))
                      : null}
                  </select>
                }
              </div>
            </div>
            <div className="col-12 mt-24">
              <div className="admin-dashboard-input">
                <label className="title2" htmlFor="topic">
                  Choose Date
                </label>
                <input type="date" value={date} onChange={(e) => setDate(e.target.value)} />
              </div>
            </div>
           
          </div>
          <button
            className="zefy_CTA_01_small_bg_black mt-48"
            onClick={handleNext}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default AskInfo;
