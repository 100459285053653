import React, { useEffect } from 'react'
import { useState } from 'react'

const ViewBox = ({data}) => {
    const [view, setView] = useState(0)
    useEffect(()=>{
        if(data){
            const len = data.length
            let sum = 0;
            for (let index = 0; index < len; index++) {
                const element = data[index];
                sum += element.views
            }
            setView(sum)
        }
    },[data])
    return (
        <p className="dash-total_views_p">{view}</p>
    )
}

export default ViewBox