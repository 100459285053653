import React from "react";

const Paragraph = ({ json }) => {
  return (
    <div className="basicMargin mb-24">
      <p className="editorTitle1">{json.content} </p>
    </div>
  );
};

export default Paragraph;
