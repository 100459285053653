import React from "react";

const Hyperlink = ({ json }) => {
  return (
    <div className="mb-24 basicMargin">
      <a
        className="editorTitle1"
        style={{ color: "blue", textDecoration: "underline" }}
        href={json.content}
        target="_blank"
      >
        {json.topic}
      </a>
    </div>
  );
};

export default Hyperlink;
