import React, { useMemo, useRef, useState } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import Quill from 'quill'
import ImageResize from 'quill-image-resize-module--fix-imports-error'
import { ImageDrop } from 'quill-image-drop-module'
import { useAuth } from '../../Utils/Auth'
import './index.css'
import EditorStartData from './EditorStartData'
import { Drawer, IconButton } from '@mui/material'
import BlogsImages from '../Admin/BlogsImages'
import { ArrowBack } from '@mui/icons-material'
import axios from 'axios'
import { toast } from 'react-toastify'
import { useNavigate, useParams } from 'react-router-dom'
import { useEffect } from 'react'
Quill.register('modules/imageResize', ImageResize)
Quill.register('modules/imageDrop', ImageDrop)

const Font = Quill.import('attributors/style/font')
Font.whitelist = [
  'Sans Serif',
  'Noto Sans',
  'Open Sans',
  'Poppins',
  'Ubuntu',
  'Montserrat',
  'Mirza',
  'Aref Ruqaa'
]
Quill.register(Font, true)
const fontSizeStyle = Quill.import('attributors/style/size')
fontSizeStyle.whitelist = [
  '8px',
  '10px',
  '12px',
  '14px',
  '16px',
  '18px',
  '20px',
  '22px',
  '24px',
  '28px',
  '32px',
  '36px',
  '40px',
  '48px',
  '50px',
  '56px',
  '60px',
  '64px',
  '68px',
  '70px',
  '72px',
  '76px',
  '80px',
  '96px',
  '100px'
]
Quill.register(fontSizeStyle, true)

const BlogEditor = () => {
  const { isAdmin } = useAuth()
  const quillRef = useRef()
  const [editorState, setEditorState] = useState(0)
  const [state, setState] = useState(false)
  const [id, setId] = useState(null)
  const { userid } = useParams()
  const navigate = useNavigate()

  const [coverTitle, setCoverTitle] = useState('')
  const [coverImage, setCoverImage] = useState('')
  const [category, setCategory] = useState('')
  const [industry, setIndustry] = useState('')
  const [location, setLocation] = useState('')
  const [link,setLink] = useState('')
  const [text, setText] = useState('')

  useEffect(() => {
    if (!isAdmin) {
      navigate(`/admin/login`)
    }
  }, [isAdmin])
  const handleChange = value => {
    setText(value)
  }

  const toggleDrawer = open => setState(open)

  const handleImage = url => {
    const editor = quillRef.current.getEditor()
    const selection = editor.getSelection()
    if (selection) {
      editor.insertEmbed(selection.index, 'image', url, Quill.sources.USER)
    }
    toggleDrawer(false)
  }

  const handlePaste = e => {
    const pastedHtml = e.clipboardData.getData('text/html')
    if (pastedHtml) {
      // Handle the pasted HTML and apply it to the editor
      setText(pastedHtml)
    }
  }

  // const fontFamilies = ['Arial', 'Verdana', 'Times New Roman', 'Courier New', 'CustomFont'];

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: [1, 2, 3, 4, 5, 6, false] }, { font: Font.whitelist }],
          ['bold', 'italic', 'underline', 'strike'],
          [
            { list: 'ordered' },
            { list: 'bullet' },
            { indent: '-1' },
            { indent: '+1' }
          ],
          [{ script: 'sub' }, { script: 'super' }],
          [{ direction: 'rtl' }],
          [
            { align: '' },
            { align: 'center' },
            { align: 'right' },
            { align: 'justify' }
          ],
          ['image', 'link'],
          [{ size: fontSizeStyle.whitelist }],
          [{ color: [] }, { background: [] }],
          ['clean'],
          ['code-block']
        ],
        handlers: {
          image: () => toggleDrawer(true)
        }
      },
      imageDrop: true,
      imageResize: {
        parchment: Quill.import('parchment'),
        modules: ['Resize', 'DisplaySize']
      },
      clipboard: {
        matchVisual: false // Disable Quill's default paste behavior
      }
    }),
    []
  )

  const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'align',
    'link',
    'image',
    'font',
    'size',
    'color',
    'background',
    'code-block'
  ]

  const saveData = async () => {
    await axios({
      url: `https://backend.zefyron.com/api/blog/publish/trendist/cc76c734-8954-4350-a2fe-8b3b88c3c48c`,
      method: 'post',
      data: {
        Content: text,
        id: id
      }
    })
      .then(res => {
        const { data } = res.data
        setId(data._id)
        toast.success('saved')
      })
      .catch(err => {
        console.log(err)
        toast.error('Error Occured')
      })
  }

  const downloadHtml = () => {
    if (text) {
      const blob = new Blob([text], { type: 'text/html' })
      const url = URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = 'content.html'
      a.click()

      URL.revokeObjectURL(url)
    }
  }

  useEffect(() => {
    if (userid) {
      ;(async () => {
        await axios({
          url: `https://backend.zefyron.com/api/blog/by-id/trendist/cc76c734-8954-4350-a2fe-8b3b88c3c48c/${userid}`,
          method: 'get'
        })
          .then(res => {
            const { data } = res.data
            setId(data._id)
            setCoverImage(data.coverImage)
            setCoverTitle(data.Title)
            setText(data.Content)
            setCategory(data.Category)
            setLocation(data.location)
            setIndustry(data.industry)
          })
          .catch(err => {
            console.log(err)
            toast.error('Error Occured')
          })
      })()
    }
  }, [userid])

  if (isAdmin) {
    return (
      <div className='blog-editor-root'>
        <div className='row justify-content-center mt-40'>
          <div className='col-lg-8 col-md-10 col-11'>
            {editorState === 0 ? (
              <EditorStartData
                setEditorState={setEditorState}
                editorState={editorState}
                coverTitle={coverTitle}
                setCoverTitle={setCoverTitle}
                coverImage={coverImage}
                setCoverImage={setCoverImage}
                id={id}
                setId={setId}
                category={category}
                setCategory={setCategory}
                industry={industry}
                setIndustry={setIndustry}
                location={location}
                setLocation={setLocation}
                setLink={setLink}
                link={link}
              />
            ) : (
              <>
                <div className='mb-24 d-flex justify-content-center align-items-center'>
                  <IconButton
                    onClick={() => setEditorState(0)}
                    className='blog_back-btn'
                  >
                    <ArrowBack />
                  </IconButton>
                  <h5 className='blog_title_text'>
                    {coverTitle} title
                  </h5>
                  <button
                    className='zefy_CTA_01_small_bg_black blog_save_btn'
                    onClick={() => downloadHtml()}
                    style={{marginRight:'100px',zIndex:100}}
                  >
                    Download HTML
                  </button>
                  <button
                    className='zefy_CTA_01_small_bg_black blog_save_btn'
                    onClick={() => saveData()}
                  >
                    Save
                  </button>
                </div>
                <ReactQuill
                  ref={quillRef}
                  theme='snow'
                  value={text}
                  onChange={handleChange}
                  modules={modules}
                  formats={formats}
                  onPaste={handlePaste}
                  className='blog-editor'
                  placeholder='write blog...'
                />
              </>
            )}
          </div>

          <Drawer
            anchor={'right'}
            open={state}
            onClose={() => toggleDrawer(false)}
            className='blogs-images-drawer'
          >
            <BlogsImages onClickFunc={handleImage} />
          </Drawer>
        </div>
      </div>
    )
  }
}

export default BlogEditor
