import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../Assets/Images/whiteLogo.png";

const LogoNav = ({topic}) => {
  console.log(topic)
  return (
    <div className="home_nav">
      <div className="container d-flex justify-content-between align-items-center">
        <div className="home_logo">
          <Link to={`/wvib/${topic}`}>
            <img src={Logo} alt="logo" />
          </Link>
        </div>
        <a href="https://zefyron.com" target="_blank" rel="noreferrer">
          <p className="home_nav_p">ZEFYRON</p>
        </a>
      </div>
    </div>
  );
};

export default LogoNav;
