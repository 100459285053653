import Axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import AdminNavbar from "../../../Components/Navbar/AdminNavbar";
import { useAuth } from "../../../Utils/Auth";

const CreateTopic = () => {
  const [users, setUsers] = useState([]);
  const [companyName, setCompanyName] = useState("");
  const [topicName, setTopicName] = useState("");
  const navigate = useNavigate();
  const { isAdmin } = useAuth();

  const getCompanis = async () => {
    await Axios({
      url: "/api/v1/user/getUsers",
      method: "get",
    })
      .then((res) => {
        setUsers(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const createTopic = async () => {
    await Axios({
      url: "/api/v1/topic/create",
      method: "post",
      data: {
        name: topicName,
        user_id: companyName,
      },
    })
      .then(() => {
        navigate(-1);
        toast.success("topic created");
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    if (!isAdmin) {
      navigate(`/admin/login`);
    }
  }, [isAdmin]);
  useEffect(() => {
    getCompanis();
  }, []);
  return (
    <div>
      <AdminNavbar />
      <div className="text-end p-24">
        <button
          className="zefy_CTA_01_small_bg_black"
          onClick={() => navigate(-1)}
        >
          Back
        </button>
      </div>
      <div className="admin-dashboard-form-root">
        <div className="admin-dashboard-form">
          <div className="admin-dashboard-form-main">
            <div className="row">
              <div className="col-12">
                <div className="admin-dashboard-input">
                  <p className="title2">Choose Company</p>
                  <div className="d-flex align-items-center mt-8">
                    {users.length > 0
                      ? users.map((user) => (
                          <div key={user._id} className="admin-form-label-main">
                            <input
                              type="radio"
                              name={"companyName"}
                              id={user._id}
                              value={user._id}
                              // checked={companyName === "google"}
                              onChange={() => setCompanyName(user._id)}
                            />
                            <label htmlFor={user._id} className="radio-images">
                              <img src={user.logo} alt="google" />
                            </label>
                          </div>
                        ))
                      : null}
                  </div>
                </div>
              </div>
              <div className="col-12 mt-24">
                <div className="admin-dashboard-input">
                  <label className="title2 mb-8" htmlFor="topic">
                    Topic Name
                  </label>
                  <input
                    type="text"
                    value={topicName}
                    onChange={(e) => setTopicName(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <button
              className="zefy_CTA_01_small_bg_black mt-48"
              onClick={createTopic}
            >
              Create Topic
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateTopic;
