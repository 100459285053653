import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import Loader from '../../Components/Loader';
import { useAuth } from '../../Utils/Auth';

const UserAdminCheck = () => {
    const navigate = useNavigate();
    const { isUserAdmin, isLoading } = useAuth();
    useEffect(() => {
        // console.log(isAdmin)
        // console.log(isLoading)

        if (isUserAdmin) {
            navigate(`/wvib/admin/views`);
        }
    }, [isLoading, isUserAdmin]);
    return (
        <div className="v_center">
            <Loader />
        </div>
    );
}

export default UserAdminCheck