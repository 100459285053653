import { Delete, Edit } from '@mui/icons-material'
import { Box, Modal, Popover, Typography } from '@mui/material'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges'
import UnpublishedIcon from '@mui/icons-material/Unpublished'
import ShareIcon from '@mui/icons-material/Share'
import CopyToClipboard from 'react-copy-to-clipboard'
import { useAuth } from '../../Utils/Auth'
import MoreVertIcon from '@mui/icons-material/MoreVert'
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: '#fff',
  //   border: '2px solid #000',
  boxShadow: 24,
  p: 4
}

const AllBlogs = () => {
  const [blogs, setBlogs] = useState([])
  const [blogsDraft, setBlogsDraft] = useState([])
  const { isAdmin } = useAuth()
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)
  const [id, setId] = useState(null)
  const [fundingNews, setFundingNews] = useState([])
  const [fundingNewsDraft, setFundingNewsDraft] = useState([])
  const handleOpen = id => {
    setId(id)
    setOpen(true)
  }
  const handleClose = () => setOpen(false)
  useEffect(() => {
    if (!isAdmin) {
      navigate(`/admin/login`)
    }
  }, [isAdmin])
  const getAllBlogs = async () => {
    await axios({
      url: `https://backend.zefyron.com/api/blog/all-published/trendist/cc76c734-8954-4350-a2fe-8b3b88c3c48c`,
      method: 'get'
    })
      .then(res => {
        const { data } = res.data
        setBlogs(data)
      })
      .catch(err => {
        console.log(err)
        toast.error('Error Occured')
      })
  }

  const getAllBlogsDrafts = async () => {
    await axios({
      url: `https://backend.zefyron.com/api/blog/all-drafts/trendist/cc76c734-8954-4350-a2fe-8b3b88c3c48c`,
      method: 'get'
    })
      .then(res => {
        const { data } = res.data
        setBlogsDraft(data)
      })
      .catch(err => {
        console.log(err)
        toast.error('Error Occured')
      })
  }

  const getAllFNDrafts = async () => {
    await axios({
      url: `https://backend.zefyron.com/api/blog/fn-drafts/trendist/cc76c734-8954-4350-a2fe-8b3b88c3c48c`,
      method: 'get'
    })
      .then(res => {
        const { data } = res.data
        console.log('fn draft', data)
        setFundingNewsDraft(data)
      })
      .catch(err => {
        console.log(err)
        toast.error('Error Occured')
      })
  }

  const getAllFN = async () => {
    await axios({
      url: `https://backend.zefyron.com/api/blog/fn-published/trendist/cc76c734-8954-4350-a2fe-8b3b88c3c48c`,
      method: 'get'
    })
      .then(res => {
        const { data } = res.data
        console.log('fn', data)
        setFundingNews(data)
      })
      .catch(err => {
        console.log(err)
        toast.error('Error Occured')
      })
  }

  useEffect(() => {
    getAllBlogs()
    getAllBlogsDrafts()
    getAllFNDrafts()
    getAllFN()
  }, [])

  const deleteBlog = async () => {
    await axios({
      url: `https://backend.zefyron.com/api/blog/delete/trendist/cc76c734-8954-4350-a2fe-8b3b88c3c48c/${id}`,
      method: 'delete'
    })
      .then(() => {
        getAllBlogs()
        getAllFNDrafts()
        getAllFN()
        handleClose()
      })
      .catch(err => {
        console.log(err)
        toast.error('Error Occured')
      })
  }

  const featuredBLog = async (flag, __id) => {
    await axios({
      url: `https://backend.zefyron.com/api/blog/mark-featured/trendist/cc76c734-8954-4350-a2fe-8b3b88c3c48c/${__id}`,
      method: 'post',
      data: {
        flag
      }
    })
      .then(() => {
        getAllBlogs()
        getAllFNDrafts()
        getAllFN()
        handleClose()
      })
      .catch(err => {
        console.log(err)
        toast.error('Error Occured')
      })
  }

  const zefChoiceBlog = async (flag, __id) => {
    await axios({
      url: `https://backend.zefyron.com/api/blog/mark-zef-choice/trendist/cc76c734-8954-4350-a2fe-8b3b88c3c48c/${__id}`,
      method: 'post',
      data: {
        flag
      }
    })
      .then(() => {
        getAllBlogs()
        getAllFNDrafts()
        getAllFN()
        handleClose()
      })
      .catch(err => {
        console.log(err)
        toast.error('Error Occured')
      })
  }

  const Trendingtopic = async (flag, __id) => {
    await axios({
      url: `https://backend.zefyron.com/api/blog/mark-trending/trendist/cc76c734-8954-4350-a2fe-8b3b88c3c48c/${__id}`,
      method: 'post',
      data: {
        flag
      }
    })
      .then(() => {
        getAllBlogs()
        getAllFNDrafts()
        getAllFN()
        handleClose()
      })
      .catch(err => {
        console.log(err)
        toast.error('Error Occured')
      })
  }

  const handlePublish = async (id) => {
    await axios({
      url: `https://backend.zefyron.com/api/blog/publish/trendist/cc76c734-8954-4350-a2fe-8b3b88c3c48c/${id}`,
      method: 'put'
    })
      .then(() => {
        getAllBlogs()
        getAllBlogsDrafts()
        getAllFNDrafts()
        getAllFN()
        handleClose()
      })
      .catch(err => {
        console.log(err)
        toast.error(err.message)
      })
  }
  const handleUnPublish = async id => {
    await axios({
      url: `https://backend.zefyron.com/api/blog/unpublish/trendist/cc76c734-8954-4350-a2fe-8b3b88c3c48c/${id}`,
      method: 'put'
    })
      .then(() => {
        getAllBlogs()
        getAllBlogsDrafts()
        getAllFNDrafts()
        getAllFN()
        handleClose()
      })
      .catch(err => {
        console.log(err)
        toast.error(err.message)
      })
  }

  const [fnTab, setfnTab] = React.useState('draft')

  const handleChange = value => {
    setfnTab(value)
  }
  const [currentBlog, setCurrentBlog] = useState(null)
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = (event, blog) => {
    setAnchorEl(event.currentTarget)
    setCurrentBlog(blog)
  }

  const handleCloseOption = () => {
    setAnchorEl(null)
  }

  const openOption = Boolean(anchorEl)
  const idOption = openOption ? 'simple-popover' : undefined

  const [anchorElFN, setAnchorElFN] = React.useState(null)

  const handleClickFN = (event, blog) => {
    setAnchorElFN(event.currentTarget)
    setCurrentBlog(blog)
  }

  const handleCloseOptionFN = () => {
    setAnchorElFN(null)
  }

  const openOptionFN = Boolean(anchorElFN)
  const idOptionFN = openOptionFN ? 'simple-popover2' : undefined

  return (
    <div className='all_blogs_root p-40'>
      <h5>Published</h5>

      <div className='row'>
        {blogs?.length > 0 ? (
          blogs.map(blog => (
            <div
              className='col-lg-3 col-md-4 col-sm-6 col-12'
              key={blog._id}
              style={{ margin: '6px 0' }}
            >
              <div className='card position-relative'>
                <div className='blog-options'>
                  <button
                  // className='editBtn trans_cta position-absolute p-10'
                  // style={{
                  //   top: 0,
                  //   right: '200px',
                  //   backgroundColor: 'white',
                  //   borderRadius: '50%'
                  // }}
                  >
                    <CopyToClipboard
                      text={`https://app.zefyron.com/blog/${blog.PostID}/${blog.Title}`}
                      className='pointer'
                      onCopy={() => toast.success('Copied')}
                    >
                      <ShareIcon fontSize='large' htmlColor='#0000ff' />
                    </CopyToClipboard>
                  </button>
                  <button
                    // className='editBtn trans_cta position-absolute p-10'
                    // style={{
                    //   top: 0,
                    //   right: '150px',
                    //   backgroundColor: 'white',
                    //   borderRadius: '50%'
                    // }}
                    onClick={() => handleUnPublish(blog._id)}
                  >
                    <UnpublishedIcon fontSize='large' htmlColor='#0000ff' />
                  </button>
                  <button
                    // className='editBtn trans_cta position-absolute p-10'
                    // style={{
                    //   top: 0,
                    //   right: '100px',
                    //   backgroundColor: 'black',
                    //   borderRadius: '50%'
                    // }}
                    onClick={() => navigate(`/admin/blogeditor/${blog._id}`)}
                  >
                    <Edit fontSize='large' htmlColor='#000' />
                  </button>
                  <button
                    // className='deleteBtn trans_cta position-absolute p-10'
                    // style={{
                    //   top: 0,
                    //   right: '50px',
                    //   backgroundColor: 'whitesmoke',
                    //   borderRadius: '50%'
                    // }}
                    onClick={() => handleOpen(blog._id)}
                  >
                    <Delete fontSize='large' htmlColor='#ff0000' />
                  </button>
                  <button
                    aria-describedby={id}
                    variant='contained'
                    onClick={e => {
                      handleClick(e, blog)
                    }}
                    // className='deleteBtn trans_cta position-absolute p-10'
                    // style={{
                    //   top: 0,
                    //   right: 0,
                    //   backgroundColor: 'whitesmoke',
                    //   borderRadius: '50%'
                    // }}
                  >
                    <MoreVertIcon fontSize='large' />
                  </button>
                </div>

                <div className='card-body'>
                  <img
                    className='img-fluid'
                    src={blog.CoverImage}
                    alt={blog.Title}
                  />
                </div>
                <div className='card-footer'>
                  <p className='body1 mt-8 text-center'>{blog.Title}</p>
                </div>
                <Popover
                  id={idOption}
                  open={openOption}
                  anchorEl={anchorEl}
                  onClose={handleCloseOption}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                  }}
                >
                  <div className='option-parent'>
                    <p
                      className='options'
                      onClick={() => {
                        featuredBLog(!currentBlog?.isfeatured, currentBlog?._id)
                        handleClick()
                      }}
                    >
                      {currentBlog?.isfeatured
                        ? 'Remove as Featured'
                        : 'Mark as Featured'}
                    </p>
                    <p
                      className='options'
                      onClick={() => {
                        zefChoiceBlog(
                          !currentBlog?.isZefChoice,
                          currentBlog?._id
                        )
                        handleClick()
                      }}
                    >
                      {currentBlog?.isZefChoice
                        ? "Remove as Zefyron's Choice"
                        : "Mark as Zefyron's Choice"}
                    </p>
                    <p
                      className='options'
                      onClick={() => {
                        Trendingtopic(
                          !currentBlog?.isTrending,
                          currentBlog?._id
                        )
                        handleClick()
                      }}
                    >
                      {currentBlog?.isTrending
                        ? 'Remove as Trending Topic'
                        : 'Mark as Trending Topic'}
                    </p>
                  </div>
                </Popover>
              </div>
            </div>
          ))
        ) : (
          <p className='no-content'>No Published Blogs</p>
        )}
      </div>

      <hr />
      <h5>Drafts</h5>
      <div className='row'>
        {blogsDraft?.length > 0 ? (
          blogsDraft.map(blog => (
            <div className='col-lg-3 col-md-4 col-sm-6 col-12' key={blog._id}>
              <div className='card position-relative'>
                <button
                  className='editBtn trans_cta position-absolute p-10'
                  style={{
                    top: 0,
                    right: '150px',
                    backgroundColor: 'white',
                    borderRadius: '50%'
                  }}
                >
                  <CopyToClipboard
                    text={`https://app.zefyron.com/blog/${blog.PostID}/${blog.Title}`}
                    className='pointer'
                    onCopy={() => toast.success('Copied')}
                  >
                    <ShareIcon fontSize='large' htmlColor='#0000ff' />
                  </CopyToClipboard>
                </button>
                <button
                  className='editBtn trans_cta position-absolute p-10'
                  style={{
                    top: 0,
                    right: '100px',
                    backgroundColor: 'white',
                    borderRadius: '50%'
                  }}
                  onClick={() => handlePublish(blog._id)}
                >
                  <PublishedWithChangesIcon
                    fontSize='large'
                    htmlColor='#0000ff'
                  />
                </button>
                <button
                  className='editBtn trans_cta position-absolute p-10'
                  style={{
                    top: 0,
                    right: '50px',
                    backgroundColor: 'black',
                    borderRadius: '50%'
                  }}
                  onClick={() => navigate(`/admin/blogeditor/${blog._id}`)}
                >
                  <Edit fontSize='large' htmlColor='#fff' />
                </button>
                <button
                  className='deleteBtn trans_cta position-absolute p-10'
                  style={{
                    top: 0,
                    right: 0,
                    backgroundColor: 'whitesmoke',
                    borderRadius: '50%'
                  }}
                  onClick={() => handleOpen(blog._id)}
                >
                  <Delete fontSize='large' htmlColor='#ff0000' />
                </button>

                <div className='card-body'>
                  <img
                    className='img-fluid'
                    src={blog.CoverImage}
                    alt={blog.Title}
                  />
                </div>
                <div className='card-footer'>
                  <p className='body1 mt-8 text-center'>{blog.Title}</p>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p className='no-content'>No Drafted Blogs</p>
        )}
      </div>

      <br />
      <hr />
      <br />
      <h5>Funding News</h5>

      <div className='d-flex align-items-center'>
        <div
          className={`fn-tab ${fnTab === 'draft' ? 'fn-tab-active' : ''}`}
          onClick={() => handleChange('draft')}
        >
          Draft
        </div>
        <div
          className={`fn-tab ${fnTab === 'published' ? 'fn-tab-active' : ''}`}
          onClick={() => handleChange('published')}
        >
          Published
        </div>
      </div>
      <div className='mt-40'>
        {fnTab === 'draft' && (
          <div className='row'>
            {fundingNewsDraft?.length > 0 ? (
              fundingNewsDraft.map(blog => (
                <div
                  className='col-lg-3 col-md-4 col-sm-6 col-12'
                  key={blog._id}
                >
                  <div className='card position-relative'>
                    <button
                      className='editBtn trans_cta position-absolute p-10'
                      style={{
                        top: 0,
                        right: '150px',
                        backgroundColor: 'white',
                        borderRadius: '50%'
                      }}
                    >
                      <CopyToClipboard
                        text={`https://zefyron.com/funding-news?id=${blog._id}`}
                        className='pointer'
                        onCopy={() => toast.success('Copied')}
                      >
                        <ShareIcon fontSize='large' htmlColor='#0000ff' />
                      </CopyToClipboard>
                    </button>
                    <button
                      className='editBtn trans_cta position-absolute p-10'
                      style={{
                        top: 0,
                        right: '100px',
                        backgroundColor: 'white',
                        borderRadius: '50%'
                      }}
                      onClick={() => handlePublish(blog._id)}
                    >
                      <PublishedWithChangesIcon
                        fontSize='large'
                        htmlColor='#0000ff'
                      />
                    </button>
                    <button
                      className='editBtn trans_cta position-absolute p-10'
                      style={{
                        top: 0,
                        right: '50px',
                        backgroundColor: 'black',
                        borderRadius: '50%'
                      }}
                      onClick={() => navigate(`/admin/funding-news?id=${blog._id}`)}
                    >
                      <Edit fontSize='large' htmlColor='#fff' />
                    </button>
                    <button
                      className='deleteBtn trans_cta position-absolute p-10'
                      style={{
                        top: 0,
                        right: 0,
                        backgroundColor: 'whitesmoke',
                        borderRadius: '50%'
                      }}
                      onClick={() => handleOpen(blog._id)}
                    >
                      <Delete fontSize='large' htmlColor='#ff0000' />
                    </button>
                    <div className='card-body'>
                      <img
                        className='img-fluid'
                        src={blog?.fundingNewsData?.coverImg}
                        alt={blog?.fundingNewsData?.title}
                      />
                    </div>
                    <div className='card-footer'>
                      <p className='body1 mt-8 text-center'>
                        {blog?.fundingNewsData?.title}
                      </p>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p className='no-content'>No Draft Funding News</p>
            )}
          </div>
        )}
      </div>
      <div className='mt-40'>
        {fnTab === 'published' && (
          <div className='row'>
            {fundingNews?.length > 0 ? (
              fundingNews.map(blog => (
                <div
                  className='col-lg-3 col-md-4 col-sm-6 col-12'
                  style={{
                    margin: '20px 0'
                  }}
                  key={blog._id}
                >
                  <div className='card position-relative'>
                    <div className='blog-options'>
                      <button
                      // className='editBtn trans_cta position-absolute p-10'
                      // style={{
                      //   top: 0,
                      //   right: '200px',
                      //   backgroundColor: 'white',
                      //   borderRadius: '50%'
                      // }}
                      >
                        <CopyToClipboard
                          text={`https://zefyron.com/funding-news?id=${blog._id}`}
                          className='pointer'
                          onCopy={() => toast.success('Copied')}
                        >
                          <ShareIcon fontSize='large' htmlColor='#0000ff' />
                        </CopyToClipboard>
                      </button>
                      <button
                        // className='editBtn trans_cta position-absolute p-10'
                        // style={{
                        //   top: 0,
                        //   right: '150px',
                        //   backgroundColor: 'white',
                        //   borderRadius: '50%'
                        // }}
                        onClick={() => handleUnPublish(blog._id)}
                      >
                        <UnpublishedIcon fontSize='large' htmlColor='#0000ff' />
                      </button>
                      <button
                        // className='editBtn trans_cta position-absolute p-10'
                        // style={{
                        //   top: 0,
                        //   right: '100px',
                        //   backgroundColor: 'black',
                        //   borderRadius: '50%'
                        // }}
                        onClick={() =>
                          navigate(`/admin/funding-news?id=${blog._id}`)
                        }
                      >
                        <Edit fontSize='large' htmlColor='#000' />
                      </button>
                      <button
                        // className='deleteBtn trans_cta position-absolute p-10'
                        // style={{
                        //   top: 0,
                        //   right: '50px',
                        //   backgroundColor: 'whitesmoke',
                        //   borderRadius: '50%'
                        // }}
                        onClick={() => handleOpen(blog._id)}
                      >
                        <Delete fontSize='large' htmlColor='#ff0000' />
                      </button>
                      <button
                        aria-describedby={idOptionFN}
                        variant='contained'
                        onClick={e => {
                          handleClickFN(e, blog)
                        }}
                        // className='deleteBtn trans_cta position-absolute p-10'
                        // style={{
                        //   top: 0,
                        //   right: 0,
                        //   backgroundColor: 'whitesmoke',
                        //   borderRadius: '50%'
                        // }}
                      >
                        <MoreVertIcon fontSize='large' />
                      </button>
                    </div>
                    <div className='card-body'>
                      <img
                        className='img-fluid'
                        src={blog?.fundingNewsData?.coverImg}
                        alt={blog?.fundingNewsData?.title}
                      />
                    </div>
                    <div className='card-footer'>
                      <p className='body1 mt-8 text-center'>
                        {blog?.fundingNewsData?.title}
                      </p>
                    </div>
                    <Popover
                      id={id}
                      open={openOptionFN}
                      anchorEl={anchorElFN}
                      onClose={handleCloseOptionFN}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left'
                      }}
                    >
                      <div className='option-parent'>
                        <p
                          className='options'
                          onClick={() => {
                            featuredBLog(
                              !currentBlog?.isfeatured,
                              currentBlog?._id
                            )
                            handleCloseOptionFN()
                          }}
                        >
                          {currentBlog?.isfeatured
                            ? 'Remove as Featured'
                            : 'Mark as Featured'}
                        </p>
                        <p
                          className='options'
                          onClick={() => {
                            zefChoiceBlog(
                              !currentBlog?.isZefChoice,
                              currentBlog?._id
                            )
                            handleCloseOptionFN()
                          }}
                        >
                          {currentBlog?.isZefChoice
                            ? "Remove as Zefyron's Choice"
                            : "Mark as Zefyron's Choice"}
                        </p>
                        <p
                          className='options'
                          onClick={() => {
                            Trendingtopic(
                              !currentBlog?.isTrending,
                              currentBlog?._id
                            )
                            handleCloseOptionFN()
                          }}
                        >
                          {currentBlog?.isTrending
                            ? 'Remove as Trending Topic'
                            : 'Mark as Trending Topic'}
                        </p>
                      </div>
                    </Popover>
                  </div>
                </div>
              ))
            ) : (
              <p className='no-content'>No Funding News</p>
            )}
          </div>
        )}
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <Typography
            tyle={{ marginBottom: '20px' }}
            id='modal-modal-title'
            variant='h4'
            component='h2'
          >
            Are you sure you want to delete?
          </Typography>
          <div className='d-flex mt-48 justify-content-between'>
            <button
              className='zefy_CTA_04_md_fill_width_outline px-5'
              onClick={handleClose}
            >
              cancel
            </button>
            <button
              className='zefy_CTA_04_md_fill_width px-5'
              onClick={() => deleteBlog()}
            >
              Delete
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  )
}

export default AllBlogs
