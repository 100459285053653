import React, { useEffect } from "react";
import { useAuth } from "../../../Utils/Auth";
import { useNavigate } from "react-router-dom";
import Loader from "../../../Components/Loader";

const AdminCheck = () => {
  const navigate = useNavigate();
  const { isAdmin, isLoading } = useAuth();
  useEffect(() => {
    // console.log(isAdmin)
    // console.log(isLoading)

    if (isAdmin) {
      navigate(`/admin/dashboard`);
    }
    // react-hooks/exhaustive-deps
  }, [isLoading, isAdmin]);
  return (
    <div className="v_center">
      <Loader />
    </div>
  );
};

export default AdminCheck;
