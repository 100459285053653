import React, { useEffect, useState } from 'react'
import WeekNavbar from "../../Components/Navbar/WeekNavar";
import { useAuth } from '../../Utils/Auth';
import './index.css'
import Axios from "axios"
import { Pagination } from '@mui/material';
import ViewWeekComp from './ViewWeekComp';
import ViewBox from './ViewBox';

const ViewDashboard = () => {
    const { user } = useAuth()
    const [data, setData] = useState([])
    const [articleCount, setArticleCount] = useState(0);
    const [articlePage, setArticlePage] = useState(1);

    const getAllViews = async (id) => {
        await Axios({
            url: `/api/v1/article/getAll/views?page=${articlePage}&size=3`,
            method: "get"
        }).then((res) => {
            setData(res.data.data)
            setArticleCount(parseInt(res.data.total_weeks));
        }).catch((err) => {
            console.log(err)
        })
    }

    function topFunction() {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }
    useEffect(() => {
        getAllViews()
        topFunction();
    }, [articlePage])

    useEffect(() => {
        if (user) {
            getAllViews();
        }
    }, [user, articlePage]);


    return <div className="dashboard-root">
        <WeekNavbar redir={"admin/login"} />
        <div className="row justify-content-center">
            <div className="col-lg-8 col-md-10 col-sm-11 col-11">
                <div className="dashboard-head dash-bottom-blue">
                    <img src={user && user.logo} alt={user?.companyName} />
                </div>
                <div className="dash-total_views_root">
                    <div className="row mt-80">
                        {
                            data.length > 0 && data.map((doc, i) => <div key={i} className="col-lg-4 col-md-4 col-sm-6 col-12 mb-lg-0 mb-md-0 mb-sm-24 mb-24">
                                <div className="dash-total_views_main">
                                    <ViewBox
                                        data={doc.document}
                                    />
                                    <div className=''>
                                        <p><span className='dash-total_views_p1'>Week {doc.document[0].week}</span> <span className='dash-total_views_p2'>({new Date(doc._id).toLocaleDateString('en-GB', {
                                            day: "2-digit",
                                            month: "short",
                                            year: "numeric"
                                        })})</span></p>
                                        {/* <p className='dash-total_views_p2'>Topic name sample text</p> */}
                                    </div>
                                </div>
                            </div>)
                        }
                    </div>
                </div>
                <div className="mt-60">
                    {
                        data.length > 0 && data.map((doc, i) => <ViewWeekComp
                            doc={doc}
                            key={i}
                        />)
                    }
                </div>
                <div className="dashboard-pagination mt-64 mb-64">
                    <Pagination
                        className="dashboard-page"
                        count={articleCount ? Math.ceil(articleCount / 3) : 0}
                        page={articlePage}
                        onChange={(event, value) => setArticlePage(value)}
                    />
                </div>
            </div>
        </div>
    </div>
}

export default ViewDashboard