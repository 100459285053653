import React from 'react'

const PageBreak = () => {
  return (
    <div className='page-break basicMargin'>
        <h4 className='page-break-none text-danger'>New page</h4>
    </div>
  )
}

export default PageBreak