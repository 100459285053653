import React from "react";

const BoldContent = ({ json }) => {
  return (
    <div className="basicMargin mb-24">
      <p className="editorh4">{json.content}</p>
    </div>
  );
};

export default BoldContent;
