import React from "react";
import "./footer.css";
import Logo from "../../Assets/Images/logo.png";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="footer-root">
      <div className="footer-main">
        <div className="foooter-logo">
          <Link to={"/wvib"}>
            <img src={Logo} alt="Logo" />
          </Link>
        </div>
        <div className="foooter-links-main">
          <div className="row">
            <div className="col-lg-5 col-md-4 col-sm-6 col-12 order-lg-0 order-md-0 order-sm-0 order-1">
              <p className="overline">© Zefyron GmbH. All rights resereved.</p>
            </div>
            <div className="col-lg-7 col-md-8 col-sm-6 col-12">
              <div className="foooter-links">
                <p className="footer-link">
                  <a
                    href="https://www.zefyron.com/privacy-policy"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Privacy Policy
                  </a>
                </p>
                <p className="footer-link">
                  <a
                    href="https://www.zefyron.com/user-agreement"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Users Agreement
                  </a>
                </p>
                <p className="footer-link">
                  <a
                    href="https://www.zefyron.com/cookie-policy"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Cookie Policy
                  </a>
                </p>
                <p className="footer-link">
                  <a
                    href="https://www.zefyron.com/imprint"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Imprint
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
