import Axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import WeekNavbar from "../../Components/Navbar/WeekNavar";
import { useAuth } from "../../Utils/Auth";
import "./index.css";
import Article from "./Article";

const ArticlePage = () => {
  const [articles, setArticles] = useState([])
  const navigate = useNavigate();
  const { user } = useAuth()
  const { week, id } = useParams()
  function topFunction() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }
  const getArticles = async (topicid) => {
    await Axios({
      url: `/api/v1/article/topic/${topicid}/${week}`,
      method: "get"
    }).then((res) => {
      setArticles(res.data.data)
    }).catch((err) => {
      console.log(err)
    })
  }
  useEffect(() => {
    topFunction();
  }, []);

  useEffect(() => {
    if (id) {
      getArticles(id)
    }
  }, [id])

  return (
    <div className="articlePage-root">
      <WeekNavbar />
      <div className="article-page_head">
        <div className="article-page_back d-flex align-items-center">
          <p className="back_p pointer" onClick={() => navigate(-1)}>
            <span className="mr-24"></span> Back
          </p>
        </div>
        <div className="mt-48 article-logo">
          <img src={user && user.logo} alt="wvin" />
        </div>
        <div className="article-title mt-24">
          <p className="fw-4">{articles[0]?.topic_id?.name} | Week {week} | {articles[0]?.date && new Date(articles[0]?.date).toLocaleDateString('en-GB')}</p>
        </div>
      </div>
      <div className="dashboard-main">
        {
          articles.length > 0 && articles.map((article, i) => <Article
            key={i}
            i={i}
            article={article}
          />)
        }

      </div>
    </div>
  );
};

export default ArticlePage;
