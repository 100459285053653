import React from "react";

const BulletList = ({ json }) => {
  return (
    <div>
      {json.listcontents &&
        json.listcontents.map((v, i) => {
          return (
            <div className="basicMargin">
              <ul key={i} role="toolbar">
                <li className="editorTitle1">{v}</li>
              </ul>
            </div>
          );
        })}
    </div>
  );
};

export default BulletList;
