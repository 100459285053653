import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../Assets/Images/logo.png";
import { useAuth } from "../../Utils/Auth";
import "./index.css";

const AdminNavbar = () => {
  const { adminSignOut } = useAuth();
  return (
    <div className="admin-navbar-root">
      <div className="container">
        <div className="admin-navbar-main d-flex justify-content-between align-items-center">
          <div>
            <Link to={"/admin/dashboard"}>
              <img src={Logo} alt="logo" />
            </Link>
          </div>
          <div className="d-flex align-items-center">
            <p
              style={{ cursor: "Pointer", fontSize: "15px" }}
              className="mr-24"
            >
              <Link to={"/admin/articles"}>Articles</Link>
            </p>
            <p
              style={{ cursor: "Pointer", fontSize: "15px" }}
              className="mr-24"
            >
              <Link to={"/admin/translate"}>Translate</Link>
            </p>
            <p
              style={{ cursor: "Pointer", fontSize: "15px" }}
              className="mr-24"
            >
              <Link to={"/admin/editor"}>Editor</Link>
            </p>
            <p
              style={{ cursor: "Pointer", fontSize: "15px" }}
              className="mr-24"
            >
              <Link to={"/admin/blogeditor"}>Blog-editor</Link>
            </p>
            <p
              style={{ cursor: "Pointer", fontSize: "15px" }}
              className="mr-24"
            >
              <Link to={"/admin/all/docs"}>All Docs</Link>
            </p>
            <p
              style={{ cursor: "Pointer", fontSize: "15px" }}
              className="mr-24"
            >
              <Link to={"/admin/all/blogs"}>All Blogs</Link>
            </p>
            <p
              style={{ cursor: "Pointer", fontSize: "15px" }}
              className="mr-24"
            >
              <Link to={"/admin/funding-news"}>Funding News</Link>
            </p>
            <p
              style={{ cursor: "Pointer", fontSize: "15px" }}
              className="mr-24"
            >
              <Link to={"/admin/trendist-mails"}>Trendist Mails</Link>
            </p>
            <p
              style={{ cursor: "Pointer", fontSize: "15px" }}
              className="mr-24"
            >
              <Link to={"/admin/published"}>Published</Link>
            </p>
            <p
              style={{ cursor: "Pointer", fontSize: "15px" }}
              onClick={adminSignOut}
            >
              Logout
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminNavbar;
